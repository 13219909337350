import * as React from "react";
import "./careers.scss";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import { graphql, StaticQuery } from "gatsby";
import {
  TypeCareersPageQueryResult,
  TypeCareersOpenPosition,
} from "../../utils/queries/careers_page";
import { PageTitle } from "../../components/title/title";
import { AboutUsBenefit } from "../about-us/index";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruptionCareersStatic } from "../../templates/resources/customers";

const OpenPosition = ({ item }: { item: TypeCareersOpenPosition }) => {
  return (
    <div className="openning-wrap">
      {item.job_category && <h3>{item.job_category}</h3>}
      <a className="job" href={item.bamboohr_link} target="_blank">
        <div className="flex justify-content-between">
          <p className="title mb-0">
            {item.position_title}
          </p>
          <p className="timezone mb-0">
            {item.timezone}
          </p>
        </div>
      </a>
    </div>
  );
};

const CareersPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeCareersPageQueryResult;
}) => {
  const edge = data.allPrismicCareersPage.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(edge.node.data, "prismicCareersPage", true);

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="careers-page">
        <PageTitle title={title} amazeeOnly={true} />
        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <section id="our-values">
          <div className="container">
            <div className="container">
              <div className="row flex justify-center">
                <div className="col-12 col-lg-10 text-center">
                  <h2>{RichTextRender(result.section_title1)}</h2>
                </div>
              </div>

              <div className="row justify-center">
                {result.services.map((service, index) => (
                  <div className="col-12 col-lg-4 service" key={index}>
                    <img src={service.image.url} alt={service.image.alt} />
                    <h3 className="service-title">{service.title1}</h3>
                    <p className="description">{service.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="benefits--section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="section-title">{result.title3}</h2>
              </div>

              <div className="col-12 col-md-10 col-lg-10">
                <div className="row align-items-start">
                  {result.benefit.map((item, index) => (
                    <AboutUsBenefit item={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="glassdoor--section pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                {/* <a href="https://www.glassdoor.com/Overview/Working-at-amazee-io-EI_IE2537132.11,20.htm"><img className='my-0 mx-auto' alt="Find us on Glassdoor." src="https://www.glassdoor.com/api/widget/verticalStarRating.htm?e=2537132" /></a> */}
                <a
                  target="_blank"
                  href="https://www.comparably.com/companies/amazee-io"
                >
                  <img
                    className="my-0 mx-auto"
                    alt="comparably"
                    src="https://images.prismic.io/amazeeio/f5ad9b1f-e1b8-464f-b2c9-d45f7d54b6e5_Comparably-Awards-2023.png?auto=compress,format"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="open-positions">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 latest-openings">
                <h2 className="title text-center text-black">{result.header}</h2>
                <div className="subtitle">
                  <p>{result.subtitle1} </p>
                </div>
                {result.open_positions.map((item, index) => (
                  <OpenPosition item={item} key={index} />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section id="image-gallery">
          <div className="container col-12 col-md-10 col-lg-8">
            <div className="row justify-center">
              <div className="col-12 col-md-6 col-lg-6 img">
                <img src={result.image_1.url} alt={result.image_1.alt} />
              </div>
              <div className="col-12 col-md-4 col-lg-4 img">
                <img src={result.image_2.url} alt={result.image_2.alt} />
              </div>
            </div>
            <div className="row justify-center">
              <div className="col-12 col-md-4 col-lg-4 img">
                <img src={result.image_3.url} alt={result.image_3.alt} />
              </div>
              <div className="col-12 col-md-6 col-lg-6 img">
                <img src={result.image_4.url} alt={result.image_4.alt} />
              </div>
            </div>
          </div>
        </section>

        <ResourceInteruptionCareersStatic topMargin={false} />
      </div>
    </AmazeePage>
  );
};

export default ({ location }: { location: Location }) => {
  const query = graphql`
    {
      allPrismicCareersPage(limit: 1) {
        edges {
          node {
            data {
              title
              meta_title
              subtitle {
                html
                text
                raw
              }
              hero_image {
                alt
                url
              }
              image {
                alt
                copyright
                url
                thumbnails
              }
              image_1 {
                alt
                copyright
                url
                thumbnails
              }
              image_2 {
                alt
                copyright
                url
                thumbnails
              }
              image_3 {
                alt
                copyright
                url
                thumbnails
              }
              image_4 {
                alt
                copyright
                url
                thumbnails
              }
              perks {
                html
                text
                raw
              }
              header
              subtitle1
              title3
              page_meta_description
              page_meta_thumbnail {
                alt
                copyright
                url
                thumbnails
              }
              benefit {
                description {
                  html
                  text
                  raw
                }
                icon {
                  alt
                  copyright
                  url
                  thumbnails
                }
                name
              }
              open_positions {
                job_category
                bamboohr_link
                position_title
                timezone
              }
              section_title1
              services {
                title1
                description
                image {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => <CareersPage location={location} data={data} />}
    />
  );
};
